<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <a-select
        class="ml-2"
        style="width: 200px;"
        @change="handleChangeCategory"
        v-model:value="category"
      >
        <a-select-option value="all">
          All Payment Categories
        </a-select-option>
        <a-select-option value="in">
          Top Up
        </a-select-option>
        <a-select-option value="out">
          Withdraw
        </a-select-option>
      </a-select>
    </div>
    <div>
      <a-table
        :class="$style.table"
        :columns="columns"
        :data-source="dataTable"
        :loading="loadingTable"
        bordered
        :pagination="globalPagination"
        @change="handleTableChange"
      >
        <template #createdAt="{ record }">
          {{ record.date }}
        </template>
        <template #totalPrice="{ record }">
          {{ commaNumber(record.currencyAmount) }}
        </template>
        <template #type="{record}">
          <a-tag color="orange" v-if="record.transactionType === 'out'">Withdraw</a-tag>
          <a-tag color="blue" v-if="record.transactionType === 'in'">Deposit</a-tag>
        </template>
        <template #detail="{record}">
          <a-tag>{{ record.title }}</a-tag>
          <!-- <a-tag color="blue" v-if="record.modelType === 'in'">Deposit</a-tag> -->
        </template>
        <template #status="{record}">
          <!-- <a-tag >{{record.modelType}}</a-tag> -->
          <a-tag color="blue" v-if="record.status === 'success'">{{
            record.status
              .replace(record.status[0], record.status[0].toUpperCase())
              .replace('_', ' ')
          }}</a-tag>
          <a-tag color="red" v-else>{{
            record.status
              .replace(record.status[0], record.status[0].toUpperCase())
              .replace('_', ' ')
          }}</a-tag>
        </template>
        <template #action="{record}">
          <a-button @click="() => handleClickDetail(record)">Detail</a-button>
        </template>
      </a-table>
    </div>
    <a-drawer
      v-model:visible="drawerDetail"
      title="Detail Withdrawal/Deposit"
      placement="right"
      @after-visible-change="afterVisibleChange"
      @close="handleCloseDrawer"
      width="700"
    >
      <a-descriptions bordered :column="2">
        <a-descriptions-item :span="2" label="Status">
          <a-badge
            :status="
              detailItem.status == 'success'
                ? 'success'
                : detailItem.status == 'on_progress'
                ? 'processing'
                : 'error'
            "
            :text="capitalizeFirstLetter(detailItem.status)"
          />
        </a-descriptions-item>
        <a-descriptions-item label="Type"
          ><a-tag color="orange" v-if="detailItem.transactionType === 'out'">Withdraw</a-tag>
          <a-tag color="blue" v-if="detailItem.transactionType === 'in'"
            >Deposit</a-tag
          ></a-descriptions-item
        >
        <a-descriptions-item label="Title">{{ detailItem.title }}</a-descriptions-item>
        <a-descriptions-item label="Ruby Amount">{{
          commaNumber(detailItem.basePriceInCoin)
        }}</a-descriptions-item>
        <a-descriptions-item label="Currency Amount"
          >IDR {{ commaNumber(detailItem.basePriceInCurrency) }}</a-descriptions-item
        >
        <a-descriptions-item label="Description">{{ detailItem.description }}</a-descriptions-item>
        <a-descriptions-item label="Fees">
          <a-list :data-source="detailItem.fees">
            <template #renderItem="{ item }">
              <a-list-item>{{ item.type }}: {{ commaNumber(item.value) }}</a-list-item>
            </template>
          </a-list>
        </a-descriptions-item>
        <a-descriptions-item label="Total"
          >IDR {{ commaNumber(detailItem.totalPrice) }}</a-descriptions-item
        >
      </a-descriptions>
    </a-drawer>
  </div>
</template>

<script>
import moment from 'moment'
import priceFormat from '@/helpers/rupiahPrice'
import { commaNumber } from '@/helpers/numberHelper'
import { onMounted, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import {
  SearchOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
import { forEach } from 'lodash'

/**
 * "data": {
        "_id": "6352f8052f8280625a744ddd",
        "modelType": "purchase",
        "transactionType": "in",
        "title": "Purchased Content",
        "description": "Your content has been purchased by user @Scherzie",
        "date": "Wed, 28 Sep, 2022",
        "time": "06:57 AM",
        "createdAt": "2022-09-28T06:57:45.759Z",
        "amount": 0,
        "currencyType": "diamonds",
        "currencyAmount": 9.5,
        "status": "success",
        "basePriceInCoin": 10,
        "basePriceInCurrency": 0,
        "fees": [
            {
                "type": "plan",
                "value": 0.5
            }
        ],
        "totalPrice": 9.5,
        "giftName": ""
    }
    "data": {
        "_id": "632992b4198af43d94cc94fb",
        "modelType": "diamonds",
        "transactionType": "out",
        "title": "Withdrawal",
        "description": "You make a withdrawal to account JOHN DOE - 12345678 for IDR 9,994,450",
        "date": "Tue, 16 Aug, 2022",
        "time": "05:52 AM",
        "createdAt": "2022-08-16T05:52:10.147Z",
        "amount": 10000,
        "currencyType": "diamonds",
        "currencyAmount": 10000,
        "status": "on_progress",
        "basePriceInCoin": 10000,
        "basePriceInCurrency": 10000000,
        "fees": [
            {
                "type": "admin",
                "value": 5550
            }
        ],
        "totalPrice": 9994450,
        "giftName": ""
    }
 */
const columns = [
  // {
  //   title: 'Invoice',
  //   dataIndex: 'external_id',
  //   key: 'external_id',
  //   slots: { customRender: 'external_id' },
  // },
  {
    title: 'Tanggal Transaksi',
    dataIndex: 'createdAt',
    key: 'createdAt',
    slots: { customRender: 'createdAt' },
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      if (new Date(b.createdAt) < new Date(a.createdAt)) {
        return -1
      }
      if (new Date(b.createdAt) > new Date(a.createdAt)) {
        return 1
      }
      // a must be equal to b
      return 0
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    slots: { customRender: 'type' },
  },
  {
    title: 'Detail',
    dataIndex: 'detail',
    key: 'detail',
    slots: { customRender: 'detail' },
  },

  // {
  //   title: 'Bank/E-Wallets',
  //   dataIndex: 'paymentMethod',
  //   key: 'paymentMethod',
  //   slots: { customRender: 'paymentMethod' },
  //   align: 'center',
  // },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    slots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: 'Total Price',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    slots: { customRender: 'totalPrice' },
    align: 'right',
  },
  {
    title: 'Aksi',
    key: 'action',
    slots: { customRender: 'action' },
    align: 'center',
  },
]
// {
//   "_id": "6352f8052f8280625a744ddd",
//   "modelType": "purchase",
//   "transactionType": "in",
//   "title": "Purchased Content",
//   "description": "@Scherzie purchase your exclusive content",
//   "date": "28/09/2022 | 06:57",
//   "createdAt": "2022-09-28T06:57:45.759Z",
//   "amount": 0,
//   "currencyType": "diamonds",
//   "currencyAmount": 9.5,
//   "status": "success"
// }
const innerColumns = [
  {
    title: 'Invoice',
    dataIndex: 'package',
    key: 'package',
    slots: { customRender: 'package' },
  },
  {
    title: 'Total Price',
    dataIndex: 'coursePrice',
    key: 'coursePrice',
    slots: { customRender: 'coursePrice' },
  },
]
export default {
  components: {
    // SearchOutlined,
    // CheckCircleOutlined,
    // ClockCircleOutlined,
    // CloseCircleOutlined,
    // PlusOutlined,
    // CheckOutlined,
    // CloseOutlined,
  },
  setup() {
    const category = ref('all')
    const store = useStore()
    const route = useRoute()
    const dataTable = ref([])
    let globalPagination = ref({ current: 1, pageSize: 10 })
    let search = ref(null)
    let sortBy = ref('createdAt')
    let order = ref('desc')
    let loadingTable = ref(false)
    const detailItem = ref({})
    const drawerDetail = ref(false)
    onMounted(() => {
      fetchTransactions()
    })

    const afterVisibleChange = bool => {
      console.log('visible', bool)
    }

    const onSearch = e => {
      console.log(e.target.value)
      fetchTransactions(e.target.value)
    }
    const handleChangeCategory = () => {
      globalPagination.value.current = 1
      fetchTransactions({ filter: category.value })
    }

    const fetchTransactions = async params => {
      loadingTable.value = true
      const apiFetchAllWithdrawalDeposit = await store.dispatch(
        'akun/ADMIN_FETCH_WITHDRAWAL_DEPOSIT_USER',
        {
          search: search.value,
          page: globalPagination.value.current,
          limit: globalPagination.value.pageSize,
          sortBy: sortBy.value,
          order: order.value,
          _id: route.params.id,
          ...params,
        },
      )
      if (apiFetchAllWithdrawalDeposit) {
        const { data } = apiFetchAllWithdrawalDeposit
        // console.log(data)

        const mappingData = data.data.map(el => {
          return {
            details: el.details,
          }
        })
        const detailsData = []
        let filteredData = []
        mappingData.forEach((el, index) => {
          detailsData.push(...el.details)
        })
        if (params?.sorter) {
          if (params.sorter.order === 'ascend') {
            console.log('ascend')
            detailsData.sort((a, b) => {
              if (new Date(b.createdAt) < new Date(a.createdAt)) {
                return -1
              }
              if (new Date(b.createdAt) > new Date(a.createdAt)) {
                return 1
              }
              // a must be equal to b
              return 0
            })
          } else if (params.sorter.order === 'descend') {
            console.log('descend')
            detailsData.sort((a, b) => {
              if (new Date(a.createdAt) < new Date(b.createdAt)) {
                return -1
              }
              if (new Date(a.createdAt) > new Date(b.createdAt)) {
                return 1
              }
              // a must be equal to b
              return 0
            })
          } else {
            detailsData.sort((a, b) => {
              if (new Date(b.createdAt) < new Date(a.createdAt)) {
                return -1
              }
              if (new Date(b.createdAt) > new Date(a.createdAt)) {
                return 1
              }
              // a must be equal to b
              return 0
            })
          }
        }
        if (params?.filter) {
          if (params.filter !== 'all') {
            filteredData = detailsData.filter(el => el.transactionType === params.filter)
          } else {
            filteredData = detailsData
          }
        } else {
          filteredData = detailsData
        }

        // console.log(detailsData)
        // console.log(

        //   'Ascend<<<<<<',
        // )
        // console.log(new Date(detailsData[0].createdAt), '<<<<< TESTING', detailsData[0])
        // console.log(detailsData)
        // console.log(
        //   detailsData.sort((a, b) => {
        //     if (new Date(b.createdAt) < new Date(a.createdAt)) {
        //       return -1
        //     }
        //     if (new Date(b.createdAt) > new Date(a.createdAt)) {
        //       return 1
        //     }
        //     // a must be equal to b
        //     return 0
        //   }),
        //   'Descend<<<<<',
        // )

        dataTable.value = filteredData.map(el => {
          return {
            key: el._id,
            ...el,
          }
        })
        console.log(detailsData, detailsData.length)
        console.log(filteredData, filteredData.length)
        globalPagination.value = { ...globalPagination.value, total: filteredData.length }
      }
      loadingTable.value = false
    }
    const handleTableChange = (pag, filters, sorter) => {
      globalPagination.value.current = pag.current
      if (sorter) {
        if (sorter.columnKey == 'key') sortBy.value = '_id'
        else sortBy.value = sorter.columnKey
        order.value = sorter.order
      }
      fetchTransactions({ sorter, filter: category.value })
    }
    const handleClickDetail = async record => {
      const apiFetchDetailWithdrawalDeposit = await store.dispatch(
        'akun/ADMIN_FETCH_DETAIL_WITHDRAWAL_DEPOSIT_USER',
        { _id: route.params.id, _detailId: record._id },
      )
      if (apiFetchDetailWithdrawalDeposit) detailItem.value = apiFetchDetailWithdrawalDeposit.data
      console.log(apiFetchDetailWithdrawalDeposit)
      drawerDetail.value = true
    }
    const handleCloseDrawer = () => {
      drawerDetail.value = false
    }

    const capitalizeFirstLetter = string => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    return {
      search,
      columns,
      dataTable,
      loadingTable,
      innerColumns,
      priceFormat,
      category,
      onSearch,
      globalPagination,
      handleTableChange,
      handleChangeCategory,
      handleClickDetail,
      detailItem,
      drawerDetail,
      afterVisibleChange,
      handleCloseDrawer,
      capitalizeFirstLetter,
      commaNumber,
    }
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
